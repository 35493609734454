.header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav a {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  padding-right: 20px;
}
.nav a:last-child {
  padding-right: 0px;
}

.right_outer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.right_btn {
  display: flex;
  align-items: center;
  backdrop-filter: blur(2px) saturate(150%);
  -webkit-backdrop-filter: blur(2px) saturate(150%);
  background-color: rgba(255, 255, 240, 0.2);
  border: 1px solid var(--border-t-color);
  border-radius: 20px;
  padding: 7px 17px;
  background-color: rgb(79, 70, 229);
  color: #fff;
}
.header_seperator {
  color: var(--border-t-color);
  margin-left: 8px;
}
.theme_switcher {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.theme_switcher img {
  max-width: 100%;
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

@media only screen and (max-width: 667px) {
  .nav {
    display: none;
  }
}
