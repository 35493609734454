.header_profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  backdrop-filter: blur(2px) saturate(150%);
  -webkit-backdrop-filter: blur(2px) saturate(150%);
  background-color: rgba(255, 255, 240, 0.2);
  border: 1px solid var(--border-t-color);
  border-radius: 20px;
  padding: 3px 3px 3px 10px;
  margin-left: 8px;
  text-align: center;
}
.header_login {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  backdrop-filter: blur(2px) saturate(150%);
  -webkit-backdrop-filter: blur(2px) saturate(150%);
  background-color: var(--theme-color);
  color: #fff;
  border: 1px solid var(--border-t-color);
  border-radius: 20px;
  padding: 6px 20px;
  margin-left: 8px;
  text-align: center;
}
.header_profile img {
  max-width: 100%;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-left: 8px;
}
.header_profile span {
  font-size: 12px;
}

@media only screen and (max-width: 410px) {
  .header_login {
    font-size: 12px;
  }
}
