.logo {
  padding: 10px 0;
}
.dark.logo {
}
.logo img {
  filter: drop-shadow(10px 10px 10px rgba(255, 255, 255, 1));
}
.dark.logo img {
  filter: none;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 5px;
  width: 197px;
}
.menu_icon {
  display: none;
  color: transparent;
  border: 3px solid var(--border-t-color);
  border-radius: 8px;
  padding: 3px;
}
.close_icon {
  align-self: flex-end;
}
.mobile_nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 50%;
  color: var(--foreground);
  background: var(--background);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 25px;
}

@media only screen and (max-width: 667px) {
  .menu_icon {
    display: block;
  }
}
