.footer_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
}
.footer_left {
  font-family: var(--font-geist-sans);
}
.footer_left a {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  padding-right: 20px;
  border-right: 1px solid;
  margin-right: 20px;
}
.footer_left a:last-child {
  padding-right: 0px;
  border-right: none;
  margin-right: 0px;
}

.footer_right {
  font-family: var(--font-geist-sans);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}
.footer_theme {
}
.footer_theme select {
  outline: none;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: transparent;
}
.footer_text {
  /* padding-right: 20px;
  border-right: 1px solid;
  margin-right: 20px; */
}

@media only screen and (max-width: 1100px) {
  .footer_inner {
    flex-direction: column;
    gap: 10px;
  }
}
@media only screen and (max-width: 650px) {
  .footer_right {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .footer_text {
    padding-right: 0px;
    margin-right: 0px;
    border-right: none;
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .footer_left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
  }
  .footer_left a {
    padding-right: 0px;
    margin-right: 0px;
    border-right: none;
  }
}
