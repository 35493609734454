@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #fafdff;
  --foreground: #171717;
  --border-color: #d1d5db;
  --theme-color: #376992;
  --border-t-color: #376992;
  --option-background: rgba(209, 213, 219, 0.3);
}

/* :root.dark {
  --background: #171717;
  --foreground: #fafdff;
  --border-color: #d1d5db;
  --border-t-color: rgba(209, 213, 219, 0.3);
  --option-background: rgba(209, 213, 219, 0.3);
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
} */

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

header {
  position: fixed;
  width: 100vw;
  margin: 0 auto;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px) saturate(180%);
  -webkit-backdrop-filter: blur(2px) saturate(180%);
  background-color: rgba(250, 253, 255, 0.75);
}
:root.dark header {
  background-color: rgba(23, 23, 23, 0.75);
}

main.main_content {
  position: relative;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}
main.main_content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/img/pharma-pattern.svg");
  background-attachment: fixed;
  background-size: 30%;
  z-index: -1;
  filter: invert(0);
}
:root.dark main.main_content::before {
  filter: invert(1);
}

footer {
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/img/pharma-pattern.svg");
  background-attachment: fixed;
  background-size: 30%;
  z-index: -1;
}
:root.dark footer::before {
  filter: invert(1);
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

.rewardSwiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}
.rewardSwiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: auto;
}
.rewardSwiper .swiper-button-prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAQMAAABtzGvEAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAAFxPH2OEauwAAAAJ0Uk5TAP9bkSK1AAAAYUlEQVR4nGNkAANG8im5R2DK7hAyVdMCpv6wgKkfHCCK8b0AiGJ+C6HuKoAo9p0OIIp/I4QqbABR8g/BRtsfYPjLgoOCKoFqgGqHGgY1GmoR1FqoI2BOgjoQ1dVQr1ASIAB00CIxCA9PvgAAAABJRU5ErkJggg==");
  background-size: cover;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 50%;
  left: 0%;
  z-index: 10;
  filter: drop-shadow(0 0 30px #333);
}
.rewardSwiper .swiper-button-next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABVQTFRFAAAAFxPHPwAAAAAAFxPGAAAAAAAAG0qm1wAAAAd0Uk5TAP8EAsABA/+U2WQAAACDSURBVHic3ZLLEYAwCAVhbMCUYimWYIeWYCkW4CR2oBeFgHkO5+S6Yfkygcc9Ah6vEwDOAFBp50gkLgumfTiaoHK5ctXlgLocUJfvXFweiMsDcX2G+Lr4EdRBWUEyQSUA5g2oYPJAucsKGkQjgUMUU3RRcLVqCp4PPLifEwVHbT51Am4r+jAxkX1/cAAAAABJRU5ErkJggg==");
  background-size: cover;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 50%;
  right: 0%;
  z-index: 10;
  filter: drop-shadow(0 0 30px #333);
}
.rewardSwiper .swiper-button-prev.swiper-button-disabled,
.rewardSwiper .swiper-button-next.swiper-button-disabled {
  display: none;
}
option {
  background-color: var(--option-background) !important;
}
.error {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.2rem;
  text-align: center;
}

@media (max-width: 640px) {
}
